<template v-if="news.length > 0">
  <section class="news">
    <div class="news__container container container__mini">
      <h2 class="news__title">{{ t('news') }}</h2>

      <div class="news__wrap">
        <article class="news__item" v-for="post in news" :key="post.id">
          <router-link tag="a" :to="{name: 'BlogNews', params: {name: post.slug}}">
            <div class="news__date">{{formatDate(post.updated_at)}}</div>
            <p class="news__descr" v-html="post.description"></p>
          </router-link>
        </article>
      </div>

      <router-link :to="{ path: '/blog'}" class="news__all">{{ t('all_news') }}</router-link>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent ({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  props: {
    news: {
      type: Array,
      required: true,
    }
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false
      };
      return new Intl.DateTimeFormat('uk-UA', options).format(date);
    }
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.news {
  @include property("padding-bottom", 100, 30);
  @include property("padding-top", 70, 25);
  background: url('~@/assets/images/bg.svg') no-repeat;
  background-size: cover;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    padding: 5px 70px;
    text-align: center;
    font-weight: 700;
    line-height: calc(40 / 32);
    @include property("font-size", 32, 24, true, 768, 320);
    background: #f9b80e;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-bottom", 55, 20);
  }

  &__wrap {
    display: grid;
    @include property("grid-column-gap", 90, 30, true, 1366, 1024);
    @include property("grid-row-gap", 60, 20);

    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 576px) and (max-width: 767.98px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__date {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 3px solid #f9b80e;
    letter-spacing: 0.8px;
    color: #f9b80e;
  }

  &__descr {
    line-height: calc(25 / 16);
    letter-spacing: 0.8px;
    color: #e1e1e1;
    margin: 0;
  }

  &__all {
    align-self: flex-end;
    line-height: calc(30 / 16);
    letter-spacing: 2.4px;
    color: #f9b80e;
    @include property("margin-top", 30, 10);
  }
}
</style>
